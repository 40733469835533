export const JOB_TYPE = {
  NON: 'non',
  COMMON: 'common',
  SALES: 'sales',
  ENGINEER: 'engineer',
  ADMINISTRATION: 'administration',
  MARKETING: 'marketing',
  SERVICE: 'service',
  CONSULTANT: 'consultant',
  FINANCE: 'finance',
  EDUCATION: 'education',
  PR: 'pr',
  CREATIVE: 'creative',
  MANUFACTURING: 'manufacturing',
  LOGISTICS: 'logistics',
  CUSTOMER_SUPPORT: 'customer_support',
  LEGAL: 'legal',
  HR: 'hr',
  ACCOUNTING: 'accounting',
  RESEARCH: 'research',
  REALESTATE: 'realestate',
  FOOD: 'food',
  CONSTRUCTION: 'construction',
  DRIVER: 'driver',
  CAREGIVER: 'caregiver',
  DENTAL_HYGIENIST: 'dental_hygienist',
  CHILDCARE: 'childcare',
  NURSE: 'nurse',
} as const;

export type JobType = keyof typeof JOB_TYPE;
