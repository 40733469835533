/** ヘッダーの最小値の高さ */
export const HEADER_MIN_HEIGHT = 64;

/** フッターの最小値の高さ */
// export const FOOTER_MIN_HEIGHT = 120;

/** PCの場合のステータスバーの最小値の高さ */
export const PC_STATUS_BAR_MIN_HEIGHT = 64;

/** SPの場合のステータスバーの最小値の高さ */
export const SP_STATUS_BAR_MIN_HEIGHT = 56;

/** 左上に表示するバツボタンの最小値の高さ */
export const CLEAR_BUTTON_MIN_HEIGHT = 64;

/** タブタイトルの最小値の高さ */
// export const TAB_TITLE_MIN_HEIGHT = 40;

const POSITION_BOTTOM = 16;
const BUTTON_PADDING = 16;

/** ボトムボタンの最小値の高さ */
export const MIN_BUTTON_HEIGHT = 40;

/** 合計72px = ボトムボタンの最小値の高さ + ボトムボタンの上部の余白（padding） */
export const MIN_BOTTOM_BUTTON_HEIGHT = MIN_BUTTON_HEIGHT + BUTTON_PADDING + POSITION_BOTTOM;
