import { useEffect } from 'react';

import { isClient } from '@/consts/device';

/**
 * @deprecated Reason: dvh, svh, lvh で対応可能なため
 */
export const useFillScreenHeight = () => {
  const setFillScreenHeight = () => {
    const vh = isClient ? window.innerHeight * 0.01 : 0;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  /** 画面の縦方向のサイズ変動があった時 or 初期描画時に高さを再計算する関数 */
  const setFillScreenHeightReCalculation = () => {
    const vh = isClient ? window.innerHeight * 0.01 : 0;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', setFillScreenHeight, { passive: true });
    return () => {
      window.removeEventListener('resize', setFillScreenHeight);
    };
  };

  useEffect(setFillScreenHeightReCalculation, []);
};
