import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { isClient } from '@/consts/device';

import type { ChangeEvent } from 'react';

const CHECKED_VALUE = 'true';
const NO_CHECKED_VALUE = 'false';

const { persistAtom } = recoilPersist({
  key: 'browser-save',
  storage: isClient ? localStorage : undefined,
});

const { persistAtom: persistTermsAtom } = recoilPersist({
  key: 'terms-and-privacy',
  storage: isClient ? localStorage : undefined,
});

const atoms = {
  /** 入力内容をブラウザに保存する場合`trueの文字列` | そうでない場合は`falseの文字列` */
  checkedValue: atom<'true' | 'false'>({
    key: 'checkedValue',
    default: CHECKED_VALUE,
    effects_UNSTABLE: [persistAtom],
  }),
  /** 利用規約に同意している場合に`trueの文字列` | そうでない場合は`falseの文字列` */
  termsCheckedValue: atom<'true' | 'false'>({
    key: 'termsCheckedValue',
    default: CHECKED_VALUE,
    effects_UNSTABLE: [persistTermsAtom],
  }),
};

export const useResumeDialog = () => {
  const [browserSaveCheckedValue, setBrowserSaveCheckedValue] = useRecoilState(atoms.checkedValue);
  const [termsCheckedValue, setTermsCheckedValue] = useRecoilState(atoms.termsCheckedValue);

  const onBrowserSaveCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setBrowserSaveCheckedValue(value === CHECKED_VALUE ? NO_CHECKED_VALUE : CHECKED_VALUE);
  };

  const onTermsCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setTermsCheckedValue(value === CHECKED_VALUE ? NO_CHECKED_VALUE : CHECKED_VALUE);
  };

  return {
    /** 入力内容をブラウザに保存する場合`trueの文字列` | そうでない場合は`falseの文字列` */
    browserSaveCheckedValue,
    /** 入力内容をブラウザに保存する場合に`true`になる */
    isBrowserSaveChecked: browserSaveCheckedValue === CHECKED_VALUE,
    /** `SessionStorage`と`LocalStorage`のどちらにデータを保存するかどうかを変更するイベントハンドラ */
    onBrowserSaveCheckboxChange,
    /** 利用規約に同意している場合`trueの文字列` | 利用規約に同意していない場合は`falseの文字列` */
    termsCheckedValue,
    /** 利用規約に同意している場合に`true`になる */
    isTermsChecked: termsCheckedValue === CHECKED_VALUE,
    /** 利用規約に同意するかしないかを変更するイベントハンドラ */
    onTermsCheckboxChange,
  };
};
