import { isClient } from '@/consts/device';

import type { StorageKeyType } from '@/hooks/use-storage/type';

export const useSessionStorage = () => {
  const getSessionStorage = <T>(key: StorageKeyType): T | null => {
    if (!isClient) return null;

    const item = sessionStorage.getItem(key);

    if (item === null) return null;

    // MEMO : { "a" : 1 } → { a : 1 } に変換する
    return JSON.parse(item);
  };

  const setSessionStorage = (key: StorageKeyType, data: unknown): void => {
    if (!isClient) return;

    try {
      // MEMO : { a : 1 } → { "a" : 1 } に変換する
      sessionStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      sessionStorage.clear();
    }
  };

  const removeSessionStorage = (key: StorageKeyType): void => {
    if (!isClient) return;

    sessionStorage.removeItem(key);
  };

  return {
    /** sessionStorageから値を取得する */
    getSessionStorage,
    /** sessionStorageに値をセットする */
    setSessionStorage,
    /** sessionStorageから値を削除する */
    removeSessionStorage,
  };
};
