import { isClient } from '@/consts/device';

import type { StorageKeyType } from '@/hooks/use-storage/type';

export const useLocalStorage = () => {
  const getLocalStorage = <T>(key: StorageKeyType): T | null => {
    if (!isClient) return null;

    const item = localStorage.getItem(key);

    if (item === null) return null;

    // MEMO : { "a" : 1 } → { a : 1 } に変換する
    return JSON.parse(item) as NonNullable<T>;
  };

  const setLocalStorage = (key: StorageKeyType, data: unknown): void => {
    if (!isClient) return;

    try {
      // MEMO : { a : 1 } → { "a" : 1 } に変換する
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      localStorage.clear();
    }
  };

  const removeLocalStorage = (key: StorageKeyType): void => {
    if (!isClient) return;

    localStorage.removeItem(key);
  };

  return {
    /** localStorageから値を取得する */
    getLocalStorage,
    /** localStorageに値をセットする */
    setLocalStorage,
    /** localStorageから値を削除する */
    removeLocalStorage,
  };
};
