export const RESUME_TITLE = {
  JIS_RESUME_TITLE: '履歴書（JIS規格）',
  MINISTRY_OF_HEALTH_RESUME_TITLE: '履歴書（厚生労働省）',
  JOB_CHANGE_TITLE: '転職用の履歴書',
  NEW_GRADUATE_TITLE: '新卒用の履歴書',
  INTERN_TITLE: 'インターン用の履歴書',
  PART_TIME_TITLE: 'アルバイト・パート用の履歴書',
  NOT_ID_PICTURE_TITLE: '証明写真なし履歴書',
  CV_RESUME_TITLE: '職務経歴書',
  CV_SALES_RESUME_TITLE: '営業の職務経歴書',
  CV_ENGINEER_RESUME_TITLE: 'ソフトウェアエンジニアの職務経歴書',
  CV_ADMINISTRATION_RESUME_TITLE: '事務職の職務経歴書',
  CV_MARKETING_RESUME_TITLE: 'マーケティングの職務経歴書',
  CV_SERVICE_RESUME_TITLE: '販売・接客職の職務経歴書',
  CV_CONSULTANT_RESUME_TITLE: 'コンサルタントの職務経歴書',
  CV_FINANCE_RESUME_TITLE: '金融・証券関連職の職務経歴書',
  CV_EDUCATION_RESUME_TITLE: '教育職の職務経歴書',
  CV_PR_RESUME_TITLE: '広報・PR職の職務経歴書',
  CV_CREATIVE_RESUME_TITLE: 'クリエイティブ職の職務経歴書',
  CV_MANUFACTURING_RESUME_TITLE: '製造・生産管理職の職務経歴書',
  CV_LOGISTICS_RESUME_TITLE: '物流・運輸関連職の職務経歴書',
  CV_CUSTOMER_SUPPORT_RESUME_TITLE: 'カスタマーサポート職の職務経歴書',
  CV_LEGAL_RESUME_TITLE: '法務・コンプライアンス職の職務経歴書',
  CV_HR_RESUME_TITLE: '人事・採用関連職の職務経歴書',
  CV_ACCOUNTING_RESUME_TITLE: '経理・財務職の職務経歴書',
  CV_RESEARCH_RESUME_TITLE: '研究・開発職の職務経歴書',
  CV_REALESTATE_RESUME_TITLE: '不動産関連職の職務経歴書',
  CV_FOOD_RESUME_TITLE: '飲食業関連職の職務経歴書',
  CV_CONSTRUCTION_RESUME_TITLE: '施工管理職の職務経歴書',
  CV_DRIVER_RESUME_TITLE: 'ドライバー職の職務経歴書',
  CV_CAREGIVER_RESUME_TITLE: '介護職の職務経歴書',
  CV_DENTAL_HYGIENIST_RESUME_TITLE: '歯科衛生士の職務経歴書',
  CV_CHILDCARE_RESUME_TITLE: '保育士の職務経歴書',
  CV_NURSE_RESUME_TITLE: '看護師の職務経歴書',
} as const;

export type ResumeTitleKey = keyof typeof RESUME_TITLE;

export type ResumeTitleType = (typeof RESUME_TITLE)[keyof typeof RESUME_TITLE];
