import styled from '@emotion/styled';

import { LAYOUT_CONTAINER_MAX_WIDTH } from '@/components/layouts/main-layout/const';
import { COLOR } from '@/styles/color';
import { MIN_BUTTON_HEIGHT } from '@/styles/height';
import { mq } from '@/styles/media';
import { Z_INDEX } from '@/styles/z-index';

export const HEADER_MIN_HEIGHT = 64;

const smoothHover = `
  transition: opacity 0.3s;
  @media (any-hover: hover) {
    &:hover {
      opacity: 0.4;
    }
  }
`;

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${HEADER_MIN_HEIGHT}px;
  width: 100%;
  padding: 0px 16px;
  max-width: ${LAYOUT_CONTAINER_MAX_WIDTH}px;
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX.HEADER};
  background-color: ${COLOR.WHITE};
`;

export const SecContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Button = styled.button`
  width: 80px;
  cursor: pointer;
  ${smoothHover}

  ${mq.sp} {
    width: 56px;
  }
`;

export const AnchorCreateResume = styled.a`
  text-align: center;
  width: 160px;
  ${smoothHover}

  @media (max-width: 768px) {
    display: none;
  }
`;
export const AnchorLink = styled.a`
  width: 80px;
  text-align: center;

  ${smoothHover}

  ${mq.sp} {
    width: 56px;
  }
`;

export const AnchorIconWrapper = styled.a`
  ${smoothHover}
`;

export const RegisterButton = styled(Button)``;

export const LoginButton = styled(Button)``;

export const DivBottomButton = styled.div`
  padding: 16px 32px 16px 16px;
  min-height: ${MIN_BUTTON_HEIGHT}px;

  ${mq.sp} {
    padding: 4px 8px;
    width: 100%;
  }
`;
