export const Z_INDEX = {
  SEARCH_FORM: 10,

  IMAGE_OVERLAY: 20,
  IMAGE_OVERLAY_ON_ELEMENT: 25,

  DRAWER_MENU_OVERLAY: 80,

  TOOLTIP: 15,

  MEDIA_HEADER: 90,
  HEADER: 45,

  // TODO : DRAWER_MENUと同じ数値になっているので修正する!!
  TOAST: 100,
  // TODO : TOASTと同じ数値になっているので修正する!!
  DRAWER_MENU: 100,
  MODAL_OVERLAY: 110,

  FULL_SCREEN_LOADER: 200,
} as const;
