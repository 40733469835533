import { useResumeDialog } from '@/hooks/use-select-cv/dialog-description/store';
import { useLocalStorage } from '@/hooks/use-storage/use-local-storage';
import { useSessionStorage } from '@/hooks/use-storage/use-session-storage';

/**
 * ### useStorage
 *
 * - ブラウザ保存にチェックがついている場合 : LocalStorageにデータを保存すること
 * - ブラウザ保存にチェックがついていない場合 : SessionStorageにデータを保存すること
 */
export const useStorage = () => {
  const { isBrowserSaveChecked } = useResumeDialog();

  const { getLocalStorage, setLocalStorage, removeLocalStorage } = useLocalStorage();
  const { getSessionStorage, setSessionStorage, removeSessionStorage } = useSessionStorage();

  const getStorage = isBrowserSaveChecked ? getLocalStorage : getSessionStorage;
  const setStorage = isBrowserSaveChecked ? setLocalStorage : setSessionStorage;
  const removeStorage = isBrowserSaveChecked ? removeLocalStorage : removeSessionStorage;

  return {
    getStorage,
    setStorage,
    removeStorage,
  };
};
