export const STORAGE_KEY = {
  /** 入力内容をブラウザに保存するかどうか（Recoil の persistAtoms で制御） */
  BROWSER_SAVE: 'browser-save',
  /** 利用規約とプライバシーポリシーに同意しているかどうか（Recoil の persistAtoms で制御） */
  TERMS_AND_PRIVACY: 'terms-and-privacy',
  /** なんの履歴書を選択したかどうか */
  SELECTED_RESUME: 'selected-resume',
  /** 一般的な履歴書を選択してフォームに入力した情報を保存する場所 */
  RESUME_COMMON: { CREATE: 'resume-common', EDIT: 'resume-common-edit' },
  /** 職務経歴書を選択してフォームに入力した情報を保存する場所 */
  CV: { CREATE: 'cv-create', EDIT: 'cv-edit' },
  /** 経由した企業のuuid群 */
  COMPANY_UUIDS: 'company-uuids',
  /** 自動生成回数 */
  GENERATED_NUMBER: 'generated-number',
  /** 現在選択している職務経歴書の職種 */
  SELECTED_CV_JOB_TYPE: 'selected-job-type',
} as const;

export type StorageKeyType =
  | 'browser-save'
  | 'terms-and-privacy'
  | 'selected-resume'
  | 'resume-common'
  | 'resume-common-edit'
  | 'cv-create'
  | 'cv-edit'
  | 'company-uuids'
  | 'generated-number'
  | 'selected-job-type';
