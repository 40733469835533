import { useRouter } from 'next/router';

import { JOB_TYPE } from '@/consts/job-type';
import { PATHS } from '@/consts/path';
import { RESUME_TITLE } from '@/consts/resume-title';
import { useStorage } from '@/hooks/use-storage';

import type { ResumeType } from '@/api/@types';
import type { ResumeTitleKey } from '@/consts/resume-title';

export const useSelectResume = () => {
  const { push } = useRouter();

  const { setStorage } = useStorage();

  const setResumeAndPreferences = (titleKey: ResumeTitleKey) => {
    setStorage('selected-resume', RESUME_TITLE[titleKey]);
    setStorage('browser-save', { checkedValue: 'true' });
    setStorage('terms-and-privacy', { termsCheckedValue: 'true' });
    setStorage('selected-job-type', JOB_TYPE.NON);
  };

  const onSelectJisResume = async () => {
    setResumeAndPreferences('JIS_RESUME_TITLE');
    await push(PATHS.RESUME_COMMON.PROFILE);
  };

  const onSelectMinistryOfHealthResume = async () => {
    setResumeAndPreferences('MINISTRY_OF_HEALTH_RESUME_TITLE');
    await push(PATHS.RESUME_COMMON.PROFILE);
  };

  const onSelectJobResume = async () => {
    setResumeAndPreferences('JOB_CHANGE_TITLE');
    await push(PATHS.RESUME_COMMON.PROFILE);
  };

  const onSelectNewGraduateResume = async () => {
    setResumeAndPreferences('NEW_GRADUATE_TITLE');
    await push(PATHS.RESUME_COMMON.PROFILE);
  };

  const onSelectInternResume = async () => {
    setResumeAndPreferences('INTERN_TITLE');
    await push(PATHS.RESUME_COMMON.PROFILE);
  };

  const onSelectPartResume = async () => {
    setResumeAndPreferences('PART_TIME_TITLE');
    await push(PATHS.RESUME_COMMON.PROFILE);
  };

  const onSelectNoIdPhotoResume = async () => {
    setResumeAndPreferences('NOT_ID_PICTURE_TITLE');
    await push(PATHS.RESUME_COMMON.PROFILE);
  };

  const findSelectResumeTypeFuncByResumeType = (resumeType: ResumeType | string) => {
    switch (resumeType) {
      case 'jis-resume':
        return onSelectJisResume;
      case 'ministry-of-health':
        return onSelectMinistryOfHealthResume;
      case 'job-change':
        return onSelectJobResume;
      case 'new-graduate':
        return onSelectNewGraduateResume;
      case 'intern':
        return onSelectInternResume;
      case 'part-time':
        return onSelectPartResume;
      case 'not-id-picture':
        return onSelectNoIdPhotoResume;
      default:
        return null;
    }
  };

  return {
    /** local storageに設定値を保存する処理 */
    setResumeAndPreferences,
    /** 履歴書（JIS規格） > 押下時のイベントハンドラ */
    onSelectJisResume,
    /** 履歴書（厚生労働省） > 押下時のイベントハンドラ */
    onSelectMinistryOfHealthResume,
    /** 転職用 > 押下時のイベントハンドラ */
    onSelectJobResume,
    /** 新卒用 > 押下時のイベントハンドラ */
    onSelectNewGraduateResume,
    /** インターン用 > 押下時のイベントハンドラ */
    onSelectInternResume,
    /** アルバイト・パート用 > 押下時のイベントハンドラ */
    onSelectPartResume,
    /** 証明写真なし履歴書 > 押下時のイベントハンドラ */
    onSelectNoIdPhotoResume,
    /** resumeType に応じて実行する関数を分岐するイベントハンドラ */
    findSelectResumeTypeFuncByResumeType,
  };
};
